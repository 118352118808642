import 'element-plus/theme-chalk/dark/css-vars.css';
import { useDark, useToggle } from '@vueuse/core';
import { reactive, toRefs, ref } from 'vue';
import { Sunny, Moon } from '@element-plus/icons-vue';
export default {
  setup() {
    const str = localStorage.getItem('themeColor');
    let visible = ref(false);
    const themeColor = ref(str || '#409eff');
    const darkM = ref(false);
    const isDark = useDark();
    darkM.value = isDark.value;
    const toggleDark = useToggle(isDark);
    const dateObj = reactive({
      month: '',
      date: '',
      hour: '',
      minute: '',
      second: '',
      week: ''
    });
    forDate();
    str && changeEvent(str);
    setInterval(forDate, 1000);
    function forDate() {
      const d = new Date();
      const month = d.getMonth() + 1;
      const date = d.getDate();
      const hour = d.getHours();
      const minute = d.getMinutes();
      const second = d.getSeconds();
      dateObj.month = month < 10 ? `0${month}` : month;
      dateObj.date = date < 10 ? `0${date}` : date;
      dateObj.hour = hour < 10 ? `0${hour}` : hour;
      dateObj.minute = minute < 10 ? `0${minute}` : minute;
      dateObj.second = second < 10 ? `0${second}` : second;
      dateObj.week = forWeek(d.getDay());
    }
    function forWeek(day) {
      switch (day) {
        case 1:
          return '一';
        case 2:
          return '二';
        case 3:
          return '三';
        case 4:
          return '四';
        case 5:
          return '五';
        case 6:
          return '六';
        case 7:
          return '日';
      }
    }
    function changeEvent(e) {
      if (!e) {
        e = '#409eff';
        themeColor.value = '#409eff';
      }
      document.body.style.setProperty('--theme-color', e);
      document.body.style.setProperty('--el-color-primary', e);
      localStorage.setItem('themeColor', e);
    }
    return {
      visible,
      Sunny,
      Moon,
      themeColor,
      darkM,
      ...toRefs(dateObj),
      toggleDark,
      changeEvent
    };
  }
};